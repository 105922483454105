import { nanoid } from 'nanoid';
import logo from "../images/map-interaction.gif"
import logo2 from "../images/stormlight.gif"
import logo3 from "../images/petsy-tour.gif"


// HEAD DATA
export const headData = {
  title: "Nick Matthews -- SWD", // e.g: 'Name | Developer'
  lang: "en", // e.g: en, es, fr, jp
  description: "Nick's Knacks", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  intro: "Hello & welcome!",
  title: `I'm`,
  name: 'Nick.',
  subtitle: "I make things & learn stuff.",
  cta: "Check me out",
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne: `There are many reasons to be a developer, but I think I'm lucky enough to be one of those
  who code for the joy of coding - I'm not lying to myself (or you), the joy isn't because every second is
  fun. The joy is the fact that often it's hard. Very, very often.`,
  paragraphTwo: `My coding philosophy is simple - solve hard problems, write beautiful code my fellow engineers will delight in working
  with, always be building, and write for people, not problems that don't exist yet. I'm happiest when I'm (sustainably) grinding on
  things that require me to constantly learn.`,
  paragraphThree: `That said, I'm not all code, and I throw myself at life as hard as I do at a language - I love comics, epic fantasy, 
  running distances I think are absurd (I'm easily impressed though), I live to travel, sci-fi of all mediums, classic kung-fu movies,
  hiking the Shenandoah's, and I consider myself a connoisseur of hard seltzers (yup).`,
  resume: 'https://docs.google.com/document/d/1LQDyIaZVQSkgjY2x2ZxATiYvBwW6Kx3n5wSjnpZqDAc', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: { logo },
    title: "Envelope",
    info: `It's not going to surprise many (anyone), but data analysis, visualization & mapping tools for non-profits suck. 
    I know because a good friend works for one, and after seeing that fact first hand I decided I'd build him something better. 
    File upload, powerful data processing, easy mapping with deep features, and beautiful visualizations. Most of the magic of this app 
    happens behind the scenes - tons of analysis & data processing, use of python threading & front-end polling to make things truly asynchronous 
    between the front & backends, and lots more.`,
    info2: "Sound fun to work on? I'm developing towards an open source project, so gimme a shout if you want to help build this into something awesome!",
    url: "https://envelope-visx.herokuapp.com/login",
    repo: 'https://github.com/nappernick/envelope', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: { logo2 },
    title: "Stormlight",
    info: `I'd heard a few horror stories from friends about working with stock API's, and it piqued my interest - they couldn't really be that bad, right?
    They are. Had a lot of fun building algo's to clean this data as the graphs are built, learning ChartJS, and creating an incredible amount of Redux store (only 4 data tables, mostly everything lives in Redux).
    This project is definitely one of my favorite examples of reusable components. React was
    built for Robinhood clones.`,
    info2: "",
    url: "https://storm-light.herokuapp.com/login",
    repo: 'https://github.com/nappernick/stormlight', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: { logo3 },
    title: "Petsy",
    info: `Team project with a pretty vanilla premise - lets make a clone of Esty. So was born Petsy! Shop for pets, sell items, fill a cart, and checkout. Not that it's
    the most impressive part of the app, but I worked hard on that footer! Mimics Etsy's pretty well, and uses IP address & a few small API's to identify where the user is based,
    the location preference, and currency used. Otherwise I was responsible for item & pet detail pages, a few chunks of the checkout, and the footer.`,
    info2: "",
    url: "https://petsy-g5.herokuapp.com/",
    repo: 'https://github.com/michael-gann/Petsy', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Wanna chat tech? An amazing offer? Lets talk!',
  btn: 'Virtual Coffee Please',
  email: 'nickfmatthews@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/nickfmatthews/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/nappernick',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
